import CircularProgress from "@mui/material/CircularProgress";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { MainPagesSectionHeader } from "./components/MainPagesSectionHeader";
import { getTextAsJSX, sortCollection } from "../../helpers/utilities";
import { CollaboratorService } from "../../services";
import { transformCloudinaryUrlTo4x3 } from "../../shared/helpers";

interface Collaborator {
  id: string;
  first_name: string;
  last_name: string;
  position: string;
  textPresentation?: string;
  imgUrl?: string;
  images?: Array<{ url: string; isMain: boolean }>;
}

interface ExtendedCollaborator extends Collaborator {
  sortingOrder?: number;
  jobTitle?: string;
}

export const MainPagesTeam: React.FC = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [collaborators, setCollaborators] = useState<ExtendedCollaborator[]>(
    []
  );

  const collaboratorService = new CollaboratorService();

  const fetchCollaborators = async () => {
    const collaborators =
      (await collaboratorService.getCollaboratorsForWeb()) as unknown as ExtendedCollaborator[];
    const sortedCollaborators = collaborators.sort(
      (a, b) => (a?.sortingOrder ?? Infinity) - (b?.sortingOrder ?? Infinity)
    );
    setCollaborators(sortedCollaborators as unknown as Collaborator[]);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchCollaborators();
    // dispatch(collaboratorsStartLoading());
  }, [dispatch]);

  return (
    <Fragment>
      <section className="u-bgPrimaryLightest u-pb-6r u-pt-10r">
        <MainPagesSectionHeader title="Equipo" />
        {isLoading ? (
          <CircularProgress />
        ) : (
          collaborators.map((collaborator) => {
            const imgUrl =
              Array.isArray(collaborator.images) &&
              collaborator.images.length > 0
                ? collaborator.images.find((image) => image.isMain)?.url ??
                  collaborator.images[0].url
                : collaborator.imgUrl;

            const transformedImgUrl = transformCloudinaryUrlTo4x3(imgUrl);

            return (
              <div
                className="teamItem u-bgPrimaryLighter u-borderPrimary"
                key={collaborator.id}
              >
                <div className="teamItem_header">
                  <h3 className="teamItem_name heading--secondary text-uppercase">
                    {`${collaborator.first_name} ${collaborator.last_name}`}
                  </h3>
                  <h4 className="teamItem_position heading--tertiary fst-italic">
                    {collaborator.jobTitle}
                  </h4>
                </div>
                <div className="teamItem_content">
                  <div className="teamItem_imgContainer">
                    <img
                      className="teamItem_img"
                      src={transformedImgUrl}
                      alt=""
                    />
                  </div>
                  <div className="teamItem_text">
                    {getTextAsJSX(collaborator.textPresentation)}
                  </div>
                </div>
              </div>
            );
          })
        )}
        <div className="container"></div>
      </section>
    </Fragment>
  );
};
