import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Chip,
} from "@mui/material";
import {
  AttendanceReport,
  AttendanceReportStatus,
} from "../../../../shared/types";
import dayjs from "dayjs";
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Warning as WarningIcon,
  Error as ErrorIcon,
} from "@mui/icons-material";

interface Props {
  data: AttendanceReport[];
}

const formatTime = (date: Date | null) => {
  if (!date) return "--:--";
  return dayjs(date).format("HH:mm");
};

const formatDuration = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return `${hours}h ${remainingMinutes}m`;
};

// {row.specialConditions === "No shift scheduled" ? (
//   <Chip size="small" label="No Shift" />
// ) : row.specialConditions ===
//   "Attendance without scheduled shift" ? (
//   <Chip
//     size="small"
//     icon={<WarningIcon />}
//     label="Unscheduled"
//     color="warning"
//   />
// ) : row.assistanceRecord ? (
//   <Chip
//     size="small"
//     icon={<CheckCircleIcon />}
//     label="Present"
//     color="success"
//   />
// ) : (
//   <Chip
//     size="small"
//     icon={<CancelIcon />}
//     label="Absent"
//     color="error"
//   />
// )}
export const AttendanceReportTable = ({ data }: Props) => {
  const renderChipByStatus = (status: AttendanceReportStatus) => {
    let icon: React.ReactNode = <CheckCircleIcon />;
    let color: "success" | "warning" | "info" | "error" | "default" = "success";
    let customColor: string = "";

    switch (status) {
      case "ASISTENCIA":
        icon = <CheckCircleIcon />;
        color = "success";
        break;
      case "DESCANSO":
        icon = <CheckCircleIcon />;
        color = "info";
        break;
      case "RETARDO":
      case "SALIDA ANTICIPADA":
        icon = <WarningIcon />;
        customColor = "#ffd700";
        break;
      case "INCOMPLETA":
      case "NO CALENDARIZADA":
        icon = <WarningIcon />;
        color = "warning";
        break;
      case "INJUSTIFICADA":
        icon = <CancelIcon />;
        color = "error";
        break;
      case "FUTURO":
        icon = <WarningIcon />;
        color = "warning";
        break;
      case "ERROR IMPREVISTO":
        icon = <ErrorIcon />;
        color = "error";
        break;
    }

    return (
      <Chip
        label={status}
        size="small"
        sx={
          customColor
            ? {
                backgroundColor: customColor,
                color: "black",
                "& .MuiChip-icon": {
                  color: "black",
                },
              }
            : undefined
        }
        color={customColor ? "default" : color}
        icon={icon}
      />
    );
  };

  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Shift Time</TableCell>
            <TableCell>Clock In/Out</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Worked Time</TableCell>
            <TableCell>Delay</TableCell>
            <TableCell>Extra Hours</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.date.toString()}>
              <TableCell>
                <Typography variant="body2">
                  {dayjs(row.date).format("ddd, MMM D")}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  {formatTime(row.shiftStartingTime)} -{" "}
                  {formatTime(row.shiftEndingTime)}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  {formatTime(row.attendanceStartingTime)} -{" "}
                  {formatTime(row.attendanceEndingTime)}
                </Typography>
              </TableCell>
              <TableCell>{renderChipByStatus(row.status)}</TableCell>
              <TableCell>
                <Typography variant="body2">
                  {formatDuration(row.workedTime)}
                </Typography>
              </TableCell>
              <TableCell>
                {row.minutesDelay > 0 && (
                  <Chip
                    size="small"
                    label={`${row.minutesDelay}m`}
                    color={row.minutesDelay > 30 ? "error" : "warning"}
                  />
                )}
              </TableCell>
              <TableCell>
                {row.extraHours > 0 && (
                  <Chip
                    size="small"
                    label={`${row.extraHours.toFixed(1)}h`}
                    color="info"
                  />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
    </TableContainer>
  );
};
