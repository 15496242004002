import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { HomeDashPage } from "../pages/dashboard/home/HomeDashPage";
import NewUser from "../pages/dashboard/users/newUser/NewUser";
import User from "../pages/dashboard/user/User";
import Users from "../pages/dashboard/users/newUser/Users";
import { CleanUpsLayoutPage } from "../pages/dashboard/cleanUps/CleanUpsLayout";
import { CreateNewRFC } from "../pages/dashboard/rfc/CreateNewRFC";
import { EditRFC } from "../pages/dashboard/rfc/EditRFC";
import { AuthLogPage } from "../pages/dashboard/authLogs/AuthLogPage";
import { Misc } from "../pages/dashboard/misc/Misc";
import { Documentation } from "../pages/dashboard/documentation/Documentation";
import { DocumentationForm } from "../pages/dashboard/documentation/components/DocumentationForm";
import { FcmIndex } from "../pages/dashboard/fcm/FcmIndex";
import { FcmPartnersIndex } from "../pages/dashboard/fcm/FcmPartnersIndex";
import { FcmDogsIndex } from "../pages/dashboard/fcm/FcmDogsIndex";
import { FcmTransfersIndex } from "../pages/dashboard/fcm/FcmTransfersIndex.jsx";
import { FcmTransfer } from "../pages/dashboard/fcm/FcmTransfer";
import { FcmPackagesIndex } from "../pages/dashboard/fcm/FcmPackagesIndex";
import { FcmPackage } from "../pages/dashboard/fcm/FcmPackage";
import { TimeOffRequestsLayout } from "../pages/dashboard/time-off-requests/TimeOffRequestsLayout";
import {
  TimeOffRequestFormWrapper,
  TimeOffRequestsHome,
  TimeOffsCalendarYear,
  TimeOffRequestsCollaborator,
  TimeOffRequestsAll,
  TimeOffRequestDetailView,
  TimeOffRequestsCollaboratorInfo,
  TimeOffRequestsSummaryOverview,
} from "../pages/dashboard/time-off-requests/components";
import {
  TasksLayout,
  Kanban,
  TaskView,
  CreateTask,
  TaskList,
  MyTasks,
  TaskStats,
  TaskReport,
} from "../pages/dashboard/tasks";
import {
  WorkLogsLayout,
  AllLogs,
  LastLogView,
  MyLogs,
  RecentLogs,
} from "../pages/dashboard/workLogs";
import { AttendanceRecordsLayout } from "../pages/dashboard/attendanceRecords";
import {
  BillList,
  BillingLayout,
  BillingMain,
  CreateOrUpdateBillInfo,
  CustomerRFCCreateUpdate,
  CustomerRFCList,
} from "../pages/dashboard/billing";
import { Notifications } from "../pages/dashboard/notifications/Notifications";

import {
  ActivityRegisterLayout,
  CollaboratorsLayout,
  ProductsLayout,
  SuppliersLayout,
} from "../pages/dashboard";
import { ControlledPrescriptionLayout } from "../pages/dashboard/controlled-prescriptions";
import { WeekShiftsLayout } from "../pages/dashboard/work-shifts";
import { ParametersLayout } from "../pages/dashboard/pararameters";

export const DashboardRouter = () => {
  return (
    <Routes>
      {/* HOME */}
      <Route path="home" element={<HomeDashPage />} />

      {/* Users */}
      <Route path="users" element={<Users />} />
      <Route path="users/newUser" element={<NewUser />} />
      <Route path="users/:userId" element={<User />} />

      <Route path="collaborators/*" element={<CollaboratorsLayout />} />

      {/* Cleanups */}
      <Route path="cleanups/*" element={<CleanUpsLayoutPage />} />

      {/* RFC */}
      <Route path="rfc" element={<CreateNewRFC />} />
      <Route path="rfc/:rfcId" element={<EditRFC />} />

      {/* AuthLogs */}
      <Route path="authLogs" element={<AuthLogPage />} />

      {/* Misc */}
      <Route path="misc" element={<Misc />} />
      {/* Documentation */}
      <Route path="documentation" element={<Documentation />} />
      <Route path="documentation/:docId" element={<DocumentationForm />} />

      {/* FCM */}
      <Route path="fcm" element={<FcmIndex />} />
      <Route path="fcm/fcmPartners" element={<FcmPartnersIndex />} />
      {/* <Route path="fcm/fcmPartners/createNew" element={<FcmPartner />} />
      <Route path="fcm/fcmPartners/:id" element={<FcmPartner />} /> */}
      <Route path="fcm/fcmDogs" element={<FcmDogsIndex />} />
      {/* <Route path="fcm/fcmDogs/createNew" element={<FcmDog />} />
      <Route path="fcm/fcmDogs/:id" element={<FcmDog />} /> */}
      <Route path="fcm/fcmTransfers" element={<FcmTransfersIndex />} />
      <Route path="fcm/fcmTransfers/createNew" element={<FcmTransfer />} />
      <Route path="fcm/fcmTransfers/:id" element={<FcmTransfer />} />
      <Route path="fcm/fcmPackages" element={<FcmPackagesIndex />} />
      <Route path="fcm/fcmPackages/createNew" element={<FcmPackage />} />
      <Route path="fcm/fcmPackages/:fcmPackageId" element={<FcmPackage />} />

      {/* TimeOffRequests */}

      <Route path="time-off-requests" element={<TimeOffRequestsLayout />}>
        <Route path={""} element={<TimeOffRequestsHome />} />
        <Route path={"calendar"} element={<TimeOffsCalendarYear />} />
        <Route
          path={"request-vacations"}
          element={<TimeOffRequestFormWrapper />}
        />
        <Route
          path={"requests-status"}
          element={<TimeOffRequestsCollaborator />}
        />
        <Route path={"approval"} element={<TimeOffRequestsAll />} />
        <Route
          path={":user-profile"}
          element={<TimeOffRequestsCollaboratorInfo />}
        />
        <Route
          path={":general-statistics"}
          element={<TimeOffRequestsSummaryOverview />}
        />
        <Route path={":id"} element={<TimeOffRequestDetailView />} />
      </Route>

      {/* Tasks */}

      <Route path="tasks" element={<TasksLayout />}>
        <Route path={""} element={<Kanban />} />
        <Route path={"list"} element={<TaskList />} />
        <Route path={"create"} element={<CreateTask />} />
        <Route path={"my-tasks"} element={<MyTasks />} />
        <Route path={"stats"} element={<TaskStats />} />
        <Route path={"report"} element={<TaskReport />} />
        <Route path={":id"} element={<TaskView />} />

        <Route path="*" element={<Navigate to="" />} />
      </Route>

      {/* LogEntity */}

      <Route path="work-logs" element={<WorkLogsLayout />}>
        <Route path="" element={<Navigate to="last-log" />} />
        <Route path={"last-log"} element={<LastLogView />} />
        <Route path={"recent"} element={<RecentLogs />} />
        <Route path={"my-logs"} element={<MyLogs />} />
        <Route path={"all-logs"} element={<AllLogs />} />
        <Route path="*" element={<Navigate to="last-log" />} />
      </Route>

      {/* TimeAttendance */}
      <Route
        path="attendance-records/*"
        element={<AttendanceRecordsLayout />}
      />
      {/*       
      <Route path="attendance-records" element={<AttendanceRecordsLayout />}>
        <Route path="" element={<AttendanceRecordsMain />} />
        <Route path="history" element={<AttendanceRecordsHistory />} />
        <Route path="admin-panel" element={<AttendanceRecordsAll />} />
        <Route path={":id"} element={<AttendanceRecordView />} />

        <Route path="*" element={<Navigate to="" />} />
      </Route>

      <Route path="*" element={<Navigate to="" />} /> */}

      {/* Billing */}

      <Route path="billing" element={<BillingLayout />}>
        <Route path="" element={<BillingMain />} />
        <Route path="create-bill" element={<CreateOrUpdateBillInfo />} />
        <Route path="create-bill/:id" element={<CreateOrUpdateBillInfo />} />
        <Route path="customer-rfcs" element={<CustomerRFCCreateUpdate />} />
        <Route path="customer-rfcs/list" element={<CustomerRFCList />} />
        <Route path="customer-rfcs/:id" element={<CustomerRFCCreateUpdate />} />
        <Route path="bill-list" element={<BillList />} />

        <Route path="*" element={<Navigate to="" />} />
      </Route>

      {/* notifications */}
      <Route path="notifications" element={<Notifications />} />

      <Route path="activity-register/*" element={<ActivityRegisterLayout />} />
      <Route path="products/*" element={<ProductsLayout />} />
      <Route path="suppliers/*" element={<SuppliersLayout />} />
      <Route
        path="controlled-prescriptions/*"
        element={<ControlledPrescriptionLayout />}
      />
      {/* WorkShifts */}

      <Route path="work-shifts/*" element={<WeekShiftsLayout />} />
      <Route path="parameters/*" element={<ParametersLayout />} />
      <Route path="" element={<Navigate to="home" />} />
    </Routes>
  );
};
